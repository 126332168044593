
import './App.css';
import React, { useState } from 'react';
import gato from './img/gato.png';
import gato2 from './img/gato2.png';
import gato3 from './img/gato3.gif';
import dragon from './img/dragon.jpg';
import pajaro from './img/pajaro.png';
import './App.css'

  const imagenes = [
    { id: 1, src: gato, titulo: 'Título Gato CEO 1' },
    { id: 2, src: gato2, titulo: 'Título Gato 2, No Stress!' },
    { id: 3, src: gato3, titulo: 'Título Gato 3, Hacker!' },
    { id: 4, src: dragon, titulo: 'Título Dragón 4 (chulísimo)!!!' },
    { id: 5, src: pajaro, titulo: 'Título Pájaro 5!!!!' },
  ];
const App = () => {

  const [indice, setIndice] = useState(0);

  const cambiarImagen = () => {

    setIndice((anteriorIndice) => (anteriorIndice +2 ) % imagenes.length );
  };
  return (
    <div className='App'>
      <img className='imagenes_gatunas' 
      src={imagenes[indice].src} onClick={cambiarImagen}></img>
      <h2>{imagenes[indice].titulo}</h2>
    </div>
  );
};

export default App;


